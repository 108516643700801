import DashboardCarousel from "./DashboardCarousel";
import MainContent from "../layout/MainContent";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import ReactMarquee from "../../components/common/Marquee";
import { Card, CardHeader, Divider, Typography } from "@mui/material";
import Counting from "../components/Counting";
import OurCompany from "../components/OurCompany";
import OurProducts from "../components/OurProducts";
import OurValues from "../components/OurValues";
import OurPromise from "../components/OurPromise";

const Dashboard = () => {

    return (
        <div>
            <DashboardCarousel />
            <ReactMarquee style={{ height: '50px', background: '#ED217C', color: 'white' }}>
                <Grid container sx={{ color: 'white' }} spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 8, md: 12 }}>
                    <Grid size={{ xs: 2, sm: 4, md: 4 }}>
                        <Card sx={{ background: 'none', border: 'none' }}>
                            <CardHeader
                                sx={{ color: 'white' }}
                                title={
                                    <b>Quality</b>
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 2, sm: 4, md: 4 }}>
                        <Card sx={{ background: 'none', border: 'none' }}>
                            <CardHeader
                                sx={{ color: 'white' }}
                                title={
                                    <b>
                                        Affordability
                                    </b>
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 2, sm: 4, md: 4 }}>
                        <Card sx={{ background: 'none', border: 'none' }}>
                            <CardHeader
                                sx={{ color: 'white' }}
                                title={
                                    <b>
                                        Accessibility
                                    </b>
                                }
                            />
                        </Card>
                    </Grid>
                </Grid>
            </ReactMarquee>
            <Grid className='oc-bg' container sx={{ pt: 5, pb: 2, px: 1 }} spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 1, md: 12 }}>
                <Counting />
            </Grid>
            <Grid className='oc-bg' container sx={{ py: 1, px: 1 }} spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 1, md: 12 }}>
                <OurCompany />
            </Grid>
            <Typography variant="h3" sx={{ color: '#ED217C', fontWeight: "bold", py: 5, }} className="text-center">
                Our Products
            </Typography>
            <Grid className='' container sx={{ py: 1, px: 1 }} spacing={{ xs: 2, md: 2 }} columns={{ xs: 1, sm: 1, md: 12 }}>
                <OurProducts />
            </Grid>
            <Grid className='oc-bg' container sx={{ py: 5, px: 1 }} spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 1, md: 12 }}>
                <Grid size={{ xs: 1, sm: 1, md: 3 }}>
                    <Typography variant="h5" sx={{ color: '#ED217C', fontWeight: "bold", }} className="text-center">
                        Our Values
                    </Typography>
                </Grid>
                <Grid size={{ xs: 1, sm: 1, md: 9 }}>
                    <Typography variant="h5" sx={{ fontWeight: "bold", }}>
                        What Sets Us Apart
                    </Typography>
                    <Grid container sx={{ py: 5, px: 1 }} spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 12 }}>
                        <OurValues />
                    </Grid>
                </Grid>
            </Grid>
            <Typography className='oc-bg text-center' variant="h3" sx={{ color: '#ED217C', fontWeight: "bold", pt: 5, pb: 2 }}>
                Our promises
            </Typography>
            <OurPromise />
            {/* <MainContent /> */}
        </div>
    )
}

export default Dashboard;
