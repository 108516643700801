import axios from 'axios';
import {
    getMethod,
    getById,
    postMethod,
    putMethod,
    removeMethod,
} from './axiosMethods';

const { REACT_APP_BASE_URL } = process.env;

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: `${REACT_APP_BASE_URL}`,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Request Interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        // const token = localStorage.getItem('authToken');
        const token = true;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response Interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Handle response error
        if (error.response?.status === 401) {
            console.error('Unauthorized. Redirecting to login...');
        }
        return Promise.reject(error);
    }
);

export const httpMethods = {
    getMethod,
    getById,
    postMethod,
    putMethod,
    removeMethod,
};

export default axiosInstance;
