import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Outlet, useNavigate } from "react-router-dom";

import {
    IconButton,
    MenuIcon,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    InboxIcon,
    MailIcon,
    MenuOpenIcon,
} from "../utils/iconList";
import { AppBar, AppDrawer, DrawerHeader } from "../components/styled/LayoutStyles";
import { drawerMenu } from "../utils/sideMenu";
import { Drawer } from '@mui/material';

const AdminLayout = () => {
    const [open, setOpen] = React.useState(true);
    const [mOpen, setMOpen] = React.useState(false);
    let navigate = useNavigate();

    const handleDrwerIconClick = () => {
        setOpen(!open);
    };

    const handleDrwerClickMobile = () => {
        setMOpen(!mOpen);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} zInd={true}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrwerIconClick}
                        edge="start"
                        sx={[
                            {
                                marginRight: 2,
                                display: { xs: 'none', md: 'flex' }
                            },
                        ]}
                    >
                        {open ? <MenuOpenIcon /> : <MenuIcon />}
                    </IconButton>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrwerClickMobile}
                        edge="start"
                        sx={[
                            {
                                marginRight: 2,
                                display: { xs: 'flex', md: 'none' }
                            },
                        ]}
                    >
                        {mOpen ? <MenuOpenIcon /> : <MenuIcon />}
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Mitsun Pharma
                    </Typography>
                </Toolbar>
            </AppBar>
            <AppDrawer variant="permanent" open={open} sx={{ display: { xs: 'none', md: 'flex' } }}>
                <DrawerHeader />
                <Divider />
                <List>
                    {drawerMenu && drawerMenu.map((item, index) => (
                        <ListItem key={item.mKey} disablePadding sx={{ display: 'block' }} onClick={() => { navigate(item.rPath); }}>
                            <ListItemButton
                                sx={[
                                    {
                                        minHeight: 48,
                                        px: 2.5,
                                    },
                                    open
                                        ? {
                                            justifyContent: 'initial',
                                        }
                                        : {
                                            justifyContent: 'center',
                                        },
                                ]}
                            >
                                <ListItemIcon
                                    sx={[
                                        {
                                            minWidth: 0,
                                            justifyContent: 'center',
                                        },
                                        open
                                            ? {
                                                mr: 3,
                                            }
                                            : {
                                                mr: 'auto',
                                            },
                                    ]}
                                >
                                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                </ListItemIcon>
                                <ListItemText
                                    sx={[
                                        open
                                            ? {
                                                opacity: 1,
                                            }
                                            : {
                                                opacity: 0,
                                            },
                                    ]}
                                >
                                    {item.mText}
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </AppDrawer>
            {/* Drawer for mobile screen */}
            <Drawer anchor='left' open={mOpen}
                onClose={handleDrwerClickMobile}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: { xs: 'block', md: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                }}>
                <DrawerHeader />
                <Divider />
                <List>
                    {drawerMenu && drawerMenu.map((item, index) => (
                        <ListItem key={item.mKey} disablePadding sx={{ display: 'block' }} onClick={() => { navigate(item.rPath); }}>
                            <ListItemButton
                                sx={[
                                    {
                                        minHeight: 48,
                                        px: 1,
                                    },
                                ]}
                            >
                                <ListItemIcon
                                    sx={[
                                        {
                                            minWidth: 0,
                                            justifyContent: 'center',
                                            mr: 1,
                                        },
                                    ]}
                                >
                                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                </ListItemIcon>
                                <ListItemText
                                    sx={[
                                    ]}
                                >
                                    {item.mText}
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                <main className="admin-main">
                    <Outlet />
                </main>
            </Box>
        </Box>
    );
};

export default AdminLayout;
