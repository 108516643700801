import * as React from 'react';

import { useParams } from "react-router-dom";
// import { capitalizeFirstLetter } from "../../utils/helpers";
import { Divider, Typography } from "@mui/material";
// import Grid from '@mui/material/Grid2';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
// import ListItemAvatar from '@mui/material/ListItemAvatar';
// import Avatar from '@mui/material/Avatar';
import Zycril from './product-description/Zycril';
import Calcity from './product-description/Calcity';
import Moxidef from './product-description/Moxidef';
import Acenofen from './product-description/Acenofen';

const ProductDetails = () => {
    const { id } = useParams();
    const routeSlug = [
        {
            name: `Calcity`,
            slug: `calcity`,
            descComp: <Calcity />,
        },
        {
            name: `Zycril`,
            slug: `zycril`,
            descComp: <Zycril />,
        },
        {
            name: `Moxidef - CV - 625`,
            slug: `moxidef-cv`,
            descComp: <Moxidef />,
        },
        {
            name: `Acenofen`,
            slug: `acenofen`,
            descComp: <Acenofen />,
        },
    ];

    const getProdName = (id) => {
        let rIndex = routeSlug.findIndex(x => x.slug == id);
        return rIndex != -1 && routeSlug[rIndex];
    };

    return (
        <div style={{ padding: '30px' }}>
            <Typography variant="h4" sx={{ color: 'text.primary', fontWeight: 'normal' }}>
                Products Details: {getProdName(id).name}
            </Typography>
            <Divider />
            {getProdName(id).descComp}
            {/* <Zycril /> */}
        </div>
    )
}

export default ProductDetails;
