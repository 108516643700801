import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

const ClinicalTrials = () => {

    return (
        <div style={{ padding: '30px' }}>
            <Grid container rowSpacing={1} sx={{ mt: 2 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid size={12} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '300px' // Optional: Define a height for proper centering vertically
                }}>
                    <Typography variant="h5" sx={{ color: 'text.primary' }}>
                        Will update on this...
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default ClinicalTrials;
