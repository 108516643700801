import * as React from 'react';
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

const Counting = () => {

    const contData = [
        {
            title: "PATTERN",
            description: ``,
            quantity: 5,
        },
        {
            title: "EMPLOYEES",
            description: ``,
            quantity: 100,
        },
        {
            title: "PRODUCTS",
            description: ``,
            quantity: 10,
        },
        {
            title: "Upcoming products",
            description: ``,
            quantity: 5,
        },
    ]

    return (
        <>
            {contData.map((item, index) => <Grid size={{ xs: 1, sm: 1, md: 3 }}>
                <Card className='text-center' sx={{ background: 'none', }} key={index}>
                    <CardHeader
                        sx={{}}
                        title={
                            <Typography gutterBottom variant="h4" sx={{ pt: 1, fontWeight: "bold" }} >
                                {item.quantity} +
                            </Typography>
                        }
                    />
                    <Divider />
                    <CardContent sx={{ mt: 1 }}>
                        <Typography variant="h4" sx={{ color: '#ED217C', fontWeight: "bold" }}>
                            {item.title}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>)}
        </>
    )
}

export default Counting;
