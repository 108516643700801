import * as React from 'react';
import { Divider, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import acenofen from "../../../assets/banners/acenofen.jpeg";
import { Helmet } from 'react-helmet';

const Acenofen = () => {

    const product = {
        name: "Mitsun Pharma - Acenofen",
        description:
            "Acenofen is a pain-relief (analgesic) tablet that helps alleviate mild to moderate pain and reduce fever. It is commonly used to treat headaches, muscle pain, joint pain, menstrual cramps, and other painful conditions. Trusted for quick and effective pain management.",
        price: "Yet to update",
        image: acenofen,
    };

    return (
        <>
            <Helmet>
                <title>{product.name}</title>
                <meta name="description" content={product.description} />
                <meta name="keywords" content="Acenofen, acenofen, pain relief, pain killer, analgesic tablets, fever reducer, Mitsun Pharma" />
                <meta name="robots" content="index,follow" />
                <meta property="og:title" content={product.name} />
                <meta property="og:description" content={product.description} />
                <meta property="og:image" content={product.image} />
                <meta property="og:url" content="https://mitsunpharma.com/products/acenofen" />
                <meta property="og:type" content="product" />
                <meta charset="UTF-8" />
                <meta name="author" content="Mitsun Pharma" />
                <link rel="canonical" href="https://mitsunpharma.com/products/acenofen" />
                <meta name="google-site-verification" content="KDeexNvzIpvRxAsVHl4ONgzWXLw2-bzfvpk9_1GTyUk" />
            </Helmet>
            <Grid container rowSpacing={1} sx={{ mt: 2 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid size={{ xs: 12, sm: 12, md: 5 }}>
                    <img
                        src={acenofen}
                        alt="acenofen"
                        loading="lazy"
                        style={{ width: "-webkit-fill-available", height: 250 }}
                    />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 7 }}>
                    <Typography variant="h5" sx={{ color: 'text.primary' }}>
                        Information about Acenofen Tablet with Aceclofenac & Paracetamol Tablets
                    </Typography>
                    <List sx={{ width: '100%', p: 0 }}>
                        <ListItem alignItems="flex-start" sx={{ pl: 0, pr: 0 }}>
                            <ListItemText>
                                <Typography sx={{}}>
                                    Acenofen Tablet is a pain-relieving medicine. It is used to reduce pain and inflammation in conditions like rheumatoid arthritis, ankylosing spondylitis, and osteoarthritis. It may also be used to relieve muscle pain, back pain, toothache, or pain in the ear and throat.
                                </Typography>
                                <Typography sx={{ pt: 2 }}>
                                    Acenofen Tablet should be taken with or without food. You should take it regularly as advised by your doctor. Your doctor may change the dose and time between doses according to your pain level and your needs. Do not take more or use it for a longer duration than recommended by your doctor.
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <Divider component="li" />
                        <ListItem alignItems="flex-start" sx={{ pl: 0, pr: 0 }}>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            variant="h6"
                                            sx={{ color: 'text.primary' }}
                                        >
                                            Key Ingredients:
                                        </Typography>
                                        <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                            Aceclofenac & Paracetamol
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem alignItems="flex-start" sx={{ pl: 0, pr: 0, display: 'list-item' }}>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            variant="h6"
                                            sx={{ color: 'text.primary' }}
                                        >
                                            Key Benefits:
                                        </Typography>
                                        <div>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Acenofen Tablet is used for short-term relief of pain, inflammation, and swelling in conditions that affect joints and muscles.
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Some of the conditions in which Acenofen Tablet is used are rheumatoid arthritis, osteoarthritis, muscle pain, back pain, toothache, or pain in the ear and throat.
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Take it as it is prescribed to get the most benefit. Do not take more or longer than needed as that can be dangerous.
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                In general, you should take the lowest dose that works, for the shortest possible time. This will help you to go about your daily activities more easily and have a better, more active, quality of life.
                                            </Typography>
                                        </div>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem alignItems="flex-start" sx={{ pl: 0, pr: 0, display: 'list-item' }}>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            variant="h6"
                                            sx={{ color: 'text.primary' }}
                                        >
                                            Side effects of Acenofen Tablet:
                                        </Typography>
                                        <div>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Read the label carefully before use
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Nausea
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Vomiting
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Stomach pain/epigastric pain
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Heartburn
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Diarrhea
                                            </Typography>
                                        </div>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </>
    );
};

export default Acenofen;
