export const dashboardImgs = {
    assistants: require('../assets/dashboard-imgs/assistants.jpg'),
    biochemists: require('../assets/dashboard-imgs/biochemists.jpg'),
    illustration: require('../assets/dashboard-imgs/illustration.jpg'),
    lap: require('../assets/dashboard-imgs/lap.jpg'),
    researcher: require('../assets/dashboard-imgs/researcher.jpg'),
    scientistChecking: require('../assets/dashboard-imgs/scientist-checking.jpg'),
    scientistsLab: require('../assets/dashboard-imgs/scientists-lab.jpg'),
    assist: require('../assets/dashboard-imgs/assist.jpg'),
    tab: require('../assets/dashboard-imgs/tablets.jpg'),
    laborat: require('../assets/dashboard-imgs/laborat.jpg'),
    bottle: require('../assets/dashboard-imgs/bottle.jpg'),
}

export const tabletImgs = {
    tabletWhite: require('../assets/dashboard-imgs/tablet-white.webp'),
}
