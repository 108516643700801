import * as React from 'react';
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

const OurPromise = () => {

    return (
        <Typography variant="h6" sx={{ fontWeight: 'normal' }} className='text-center px-5 pb-4 oc-bg'>
            At Mitsun Pharmaceuticals, we are not just a business, we are a partner in healthcare, dedicated to creating a healthier, better-connected future for all. Together,
            let’s transform the way pharmaceutical marketing serves communities.
        </Typography>
    )
}

export default OurPromise;
