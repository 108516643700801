import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { dashboardImgs } from '../../utils/siteImgs'

const DashboardCarousel = () => {

    const getConfigurableProps = {
        showArrows: true,
        showStatus: false,
        showIndicators: false,
        infiniteLoop: true,
        showThumbs: false,
        useKeyboardArrows: true,
        autoPlay: true,
        stopOnHover: true,
        swipeable: true,
        dynamicHeight: true,
        emulateTouch: true,
        autoFocus: false,
        thumbWidth: 100,
        selectedItem: 0,
        interval: 3000,
        transitionTime: 500,
        swipeScrollTolerance: 5,
        ariaLabel: undefined,
    };

    return (
        <div className="carousel-container">
            <Carousel
                {...getConfigurableProps}
            >
                <div>
                    <img src={dashboardImgs.assistants} alt="1" />
                </div>
                <div>
                    <img src={dashboardImgs.biochemists} alt="2" />
                </div>
                <div>
                    <img src={dashboardImgs.tab} alt="3" />
                </div>

                <div>
                    <img src={dashboardImgs.laborat} alt="3" />
                </div>

                <div>
                    <img src={dashboardImgs.researcher} alt="3" />
                </div>

                <div>
                    <img src={dashboardImgs.scientistChecking} alt="3" />
                </div>

                <div>
                    <img src={dashboardImgs.scientistsLab} alt="3" />
                </div>
            </Carousel>
        </div>
    )
}

export default DashboardCarousel;
