import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Divider, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import moxidef from "../../../assets/banners/mox.jpeg";

const Moxidef = () => {

    const product = {
        name: "Mitsun Pharma - Moxidef-CV 625 | Antibiotic Tablets",
        description:
            "Moxidef - CV - 625 is an antibiotic tablet that helps your body fight bacterial infections. It is used to treat infections of the lungs (e.g., pneumonia), ear, nasal sinus, urinary tract, skin, and soft tissues. It is not effective against viral infections such as the common cold.",
        price: "$99.99",
        image: moxidef,
    };

    return (
        <>
            <Helmet>
                <title>{product.name}</title>
                <meta name="description" content={product.description} />
                <meta name="keywords" content="Moxidef-CV, Moxidef CV, antibiotic tablet, bacterial infections, anti-infectives, Mitsun Pharma, Moxidef-CV 625" />
                <meta name="robots" content="index,follow" />
                <meta property="og:title" content={product.name} />
                <meta property="og:description" content={product.description} />
                <meta property="og:image" content={product.image} />
                <meta property="og:url" content="https://mitsunpharma.com/products/moxidef-cv" />
                <meta property="og:type" content="product" />
                <meta charset="UTF-8" />
                <meta name="author" content="Mitsun Pharma" />
                <link rel="canonical" href="https://mitsunpharma.com/products/moxidef-cv" />
                {/* <meta http-equiv="refresh" content="5; url=https://mitsunpharma.com/products/" /> */}
                <meta name="google-site-verification" content="KDeexNvzIpvRxAsVHl4ONgzWXLw2-bzfvpk9_1GTyUk" />

            </Helmet>
            <Grid container rowSpacing={1} sx={{ mt: 2 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid size={{ xs: 12, sm: 12, md: 5 }}>
                    <img
                        src={moxidef}
                        alt="moxidef"
                        loading="lazy"
                        style={{ width: "-webkit-fill-available", height: 250 }}
                    />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 7 }}>
                    <Typography variant="h5" sx={{ color: 'text.primary' }}>
                        Information about Moxidef - CV - 625 Tablet with Amoxycillin & Clavulanic Acid Tablets
                    </Typography>
                    <List sx={{ width: '100%', p: 0 }}>
                        <ListItem alignItems="flex-start" sx={{ pl: 0, pr: 0 }}>
                            <ListItemText>
                                <Typography sx={{}}>
                                    Moxidef - CV - 625 Tablet is an antibiotic that helps your body fight infections caused by bacteria. It is used to treat infections of the lungs (e.g., pneumonia), ear, nasal sinus, urinary tract, skin, and soft tissue. It will not work for viral infections such as the common cold.
                                </Typography>

                                <Typography sx={{ pt: 2 }}>
                                    Moxidef - CV - 625 Tablet is best taken with a meal to reduce the chance of a stomach upset. You should take it regularly at evenly spaced intervals as per the schedule prescribed by your doctor. Taking it at the same time daily will help you remember to take it. The dose will depend on what you are being treated for, but you should always complete an entire course of this antibiotic as your doctor prescribes. Please do not stop taking it until you have finished, even when you feel better. If you stop taking it early, some bacteria may survive, and the infection may return or worsen.
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <Divider component="li" />
                        <ListItem alignItems="flex-start" sx={{ pl: 0, pr: 0 }}>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            variant="h6"
                                            sx={{ color: 'text.primary' }}
                                        >
                                            Key Ingredients:
                                        </Typography>
                                        <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                            Amoxycillin and Clavulanic Acid
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem alignItems="flex-start" sx={{ pl: 0, pr: 0, display: 'list-item' }}>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            variant="h6"
                                            sx={{ color: 'text.primary' }}
                                        >
                                            Key Benefits:
                                        </Typography>
                                        <div>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Moxidef - CV - 625 Tablet contains two medicines, Amoxycillin and Clavulanic Acid, that work together to kill the bacteria that cause infections. Amoxycillin works by stopping the growth of bacteria. Clavulanic Acid reduces resistance and enhances the activity of Amoxycillin against bacteria.
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Moxidef - CV - 625 Tablet can treat many bacterial infections such as ear, sinus, throat, lung, urinary tract, skin, teeth, joints, and bones. It usually makes you feel better within a few days, but you should continue taking it as prescribed even when you feel better to ensure all bacteria are killed and do not become resistant.
                                            </Typography>
                                        </div>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem alignItems="flex-start" sx={{ pl: 0, pr: 0, display: 'list-item' }}>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            variant="h6"
                                            sx={{ color: 'text.primary' }}
                                        >
                                            Safety Information:
                                        </Typography>
                                        <div>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Vaginal inflammation
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Mucocutaneous candidiasis
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Vomiting
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Nausea
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Diarrhea
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Skin rash
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Red spots or bumps
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Fungal infection
                                            </Typography>
                                        </div>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </>
    );
};

export default Moxidef;
