import * as React from 'react';
import { Outlet } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

import AppAppBar from '../user/layout/AppAppBar';
import Footer from '../user/layout/Footer';
import AppTheme from '../theme/AppTheme';

const UserLayout = (props) => {
    return (
        <>
            <AppTheme {...props} disableCustomTheme={false}>
                <CssBaseline enableColorScheme />

                <AppAppBar />
                <Container
                    maxWidth="lg"
                    component="main"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // my: 16,
                        mb: 2,
                        gap: 4,
                        "&.MuiContainer-maxWidthLg": {
                            maxWidth: "100%",
                            padding: 0
                        },
                    }}
                >
                    <Outlet />
                </Container>
                <Footer />
            </AppTheme>
        </>
    )
}

export default UserLayout;
