import * as React from 'react';
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { dashboardImgs } from '../../utils/siteImgs';

const OurCompany = () => {

    const contData = [
        {
            type: 'img',
            img: dashboardImgs.bottle
        },
        {
            title: `Our Company`,
            subTitle: <Typography gutterBottom variant="h4" sx={{ pt: 1, fontWeight: "800", fontSize: '18px' }} >
                Where Innovation Meets Dedication
            </Typography>,
            description: <Typography gutterBottom sx={{ pt: 1, fontWeight: 'normal', width: { sm: 'unset', md: '500px' }, fontSize: '16px' }}>
                At <b>Mitsun Pharmaceuticals Marketing Company</b>, we are redefining pharmaceutical marketing with a purpose-driven approach. Established with a vision to make healthcare accessible, we are dedicated to empowering healthcare providers with high-quality medications and innovative solutions
            </Typography>,
            type: 'desc',
        },
    ]

    return (
        <>
            {contData.map((item, index) => <Grid size={{ xs: 1, sm: 1, md: 6 }}>
                <Card className={item.type == 'img' ? 'text-center d-flex align-items-center justify-content-center' : 'd-flex flex-column justify-content-center'}
                    sx={{ background: 'none', border: 'none', minHeight: item.type !== 'img' ? '500px' : '' }}
                    key={index}
                >
                    {item.type == 'img' ? <Card style={{
                        width: '470px',
                        borderRadius: '35px',
                        boxShadow: '8px 8px 14px 0px rgba(0,0,0,0.5)',
                        padding: 0,
                    }}>
                        <img
                            src={item.img}
                            alt={item.type}
                            style={{
                                maxWidth: "100%",
                            }}
                        />
                    </Card> : <>
                        <CardHeader
                            sx={{}}
                            title={
                                <Typography className='font-pink' gutterBottom variant="h3" sx={{ pt: 1, fontWeight: "bold" }} >
                                    {item.title}
                                </Typography>
                            }
                        />
                        <CardContent sx={{ mt: 1 }}>
                            {item.subTitle}
                            {item.description}
                        </CardContent>
                    </>}
                </Card>
            </Grid>)}
        </>
    )
}

export default OurCompany;
