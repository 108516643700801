import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const usePageTitle = (titleMap) => {
    const location = useLocation();
    useEffect(() => {
        const title = `Mitsun Pharma ${titleMap[location.pathname] ? '-' : ''} ${titleMap[location.pathname] || ''}` || "Mitsun Pharma - ";
        document.title = title;
    }, [location, titleMap]);
};

export default usePageTitle