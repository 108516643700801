import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHub from '@mui/icons-material/GitHub';
import Instagram from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/X';
import { useNavigate } from "react-router-dom";

import SitemarkIcon from './SitemarkIcon';

import mLogo from "../../assets/images/mitsun-logo.jpg";

function Copyright() {
    return (
        <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
            {'Copyright © '}
            <Link color="text.secondary" href="#">
                Mitsun Pharma
            </Link>
            &nbsp;
            {new Date().getFullYear()}
        </Typography>
    );
}

const Footer = () => {
    let navigate = useNavigate();

    const prodClick = (route) => {
        navigate(route);
    }
    return (
        <React.Fragment>
            <Divider />
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 2, sm: 4 },
                    py: { xs: 4, sm: 5 },
                    textAlign: { sm: 'center', md: 'left' },
                    "&.MuiContainer-maxWidthLg": {
                        maxWidth: "95%",
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        width: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 4,
                            minWidth: { xs: '100%', sm: '30%' },
                        }}
                    >
                        <Box sx={{ width: { xs: '100%', sm: '100%' } }}>
                            <SitemarkIcon
                                imageUrl={mLogo}
                                altText="Custom Logo"
                                sx={{ height: 50, width: 150 }}
                            />
                            <Typography
                                variant="body2"
                                gutterBottom
                                sx={{ fontWeight: 600, mt: 2 }}
                            >
                                Join the newsletter
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
                                Subscribe for weekly updates. No spams ever!
                            </Typography>
                            <InputLabel htmlFor="email-newsletter">Email</InputLabel>
                            <Stack direction="row" spacing={1} useFlexGap>
                                <TextField
                                    id="email-newsletter"
                                    hiddenLabel
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    aria-label="Enter your email address"
                                    placeholder="Your email address"
                                    slotProps={{
                                        htmlInput: {
                                            autoComplete: 'off',
                                            'aria-label': 'Enter your email address',
                                        },
                                    }}
                                    sx={{ width: '250px' }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    sx={{ flexShrink: 0 }}
                                >
                                    Subscribe
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'flex' },
                            minWidth: '20%',
                            flexDirection: 'column',
                            gap: 1,
                        }}
                    >
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            Our Products
                        </Typography>
                        <Link className='cur-pointer prd-link' color="text.secondary" variant="body2" onClick={() => prodClick('/products/calcity')}>
                            Calcity
                        </Link>
                        <Link className='cur-pointer prd-link' color="text.secondary" variant="body2" onClick={() => prodClick('/products/zycril')}>
                            Zycril
                        </Link>
                        <Link className='cur-pointer prd-link' color="text.secondary" variant="body2" onClick={() => prodClick('/products/moxidef-cv')}>
                            Moxidef CV 625
                        </Link>
                        <Link className='cur-pointer prd-link' color="text.secondary" variant="body2" onClick={() => prodClick('/products/acenofen')}>
                            Acenofen
                        </Link>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'flex' },
                            flexDirection: 'column',
                            minWidth: '15%',
                            gap: 1,
                        }}
                    >
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            Company
                        </Typography>
                        <Link className='cur-pointer prd-link' color="text.secondary" variant="body2" href="#">
                            About us
                        </Link>
                        <Link className='cur-pointer prd-link' color="text.secondary" variant="body2" href="#">
                            Careers
                        </Link>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'flex' },
                            flexDirection: 'column',
                            minWidth: '10%',
                            gap: 1,
                        }}
                    >
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            Follow Us On
                        </Typography>
                        <GitHub className='social-icon' />
                        <FacebookIcon className='social-icon' />
                        <Instagram className='social-icon' />
                        <TwitterIcon className='social-icon' />
                        <LinkedInIcon className='social-icon' />
                    </Box>
                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'flex' },
                            flexDirection: 'column',
                            minWidth: '25%',
                            gap: 1,
                        }}
                    >
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            Contact us
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary', mb: 0 }}>
                            Bengaluru, Karnataka, India -560096
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary', mb: 0 }}>
                            +91 6369082584
                        </Typography>
                        <Link color="text.secondary" variant="body2" href="mailto:mitsunpharma@gmail.com">
                            mitsunpharma@gmail.com
                        </Link>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        pt: { xs: 2, sm: 4 },
                        width: '100%',
                        borderTop: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    <Copyright />
                    <div>
                        <Link color="text.secondary" variant="body2" href="#">
                            Privacy Policy
                        </Link>
                        <Typography sx={{ display: 'inline', mx: 0.5, opacity: 0.5 }}>
                            &nbsp;•&nbsp;
                        </Typography>
                        <Link color="text.secondary" variant="body2" href="#">
                            Terms of Service
                        </Link>
                    </div>
                </Box>
            </Container>
        </React.Fragment>
    );
}

export default Footer;
