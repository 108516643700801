
import { Routes, Route, Navigate } from "react-router-dom";
import usePageTitle from "../utils/hooks/usePageTitle";

import AdminLayout from "../layouts/AdminLayout";
import Page404 from "../components/common/Page404";

const Dashboard = () => <h1>Admin Dashboard (Private)</h1>;
const Settings = () => <h1>Settings (Private)</h1>;
const Reports = () => <h1>Reports (Private)</h1>;
const Products = () => <h1>Products (Private)</h1>;

const isAuthenticated = true;

const AdminRoutes = () => {

  const titleMap = {
    "/admin/dashboard": "Dashboard",
    "/admin/settings": "Settings",
    "/admin/reports": "Reports",
    "/admin/products": "Products",
  };
  usePageTitle(titleMap);

  return isAuthenticated ? (
    <Routes>
      <Route path="/" element={<AdminLayout />}>
        <Route path="/" element={<Navigate to="/admin" />} />
        <Route path="" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/products" element={<Products />} />
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  ) : (
    <Navigate to="/login" />
  );
}

export default AdminRoutes;
