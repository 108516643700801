import * as React from 'react';
import { Divider, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import calcity from "../../../assets/banners/calcity.jpeg";
import { Helmet } from 'react-helmet';

const Calcity = () => {
    const product = {
        name: "Mitsun Pharma - Calcity",
        description:
            "Calcity is a calcium supplement that helps in maintaining strong bones and teeth. It supports overall bone health and prevents calcium deficiency-related conditions. Recommended for individuals with increased calcium needs or dietary deficiencies.",
        price: "Yet to update",
        image: calcity,
    };

    return (
        <>
            <Helmet>
                <title>{product.name}</title>
                <meta name="description" content={product.description} />
                <meta name="keywords" content="Calcity, calcity, Calcium Supplement, bone health, Mitsun Pharma" />
                <meta name="robots" content="index,follow" />
                <meta property="og:title" content={product.name} />
                <meta property="og:description" content={product.description} />
                <meta property="og:image" content={product.image} />
                <meta property="og:url" content="https://mitsunpharma.com/products/calcity" />
                <meta property="og:type" content="product" />
                <meta charset="UTF-8" />
                <meta name="author" content="Mitsun Pharma" />
                <link rel="canonical" href="https://mitsunpharma.com/products/calcity" />
                <meta name="google-site-verification" content="KDeexNvzIpvRxAsVHl4ONgzWXLw2-bzfvpk9_1GTyUk" />
            </Helmet>
            <Grid container rowSpacing={1} sx={{ mt: 2 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid size={{ xs: 12, sm: 12, md: 5 }}>
                    <img
                        src={calcity}
                        alt="calcity"
                        loading="lazy"
                        style={{ width: "-webkit-fill-available", height: 250 }}
                    />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 7 }}>
                    <Typography variant="h5" sx={{ color: 'text.primary' }}>
                        Information about Calcity Tablet with Calcium Carbonate & Vitamin D3 IU Tablets
                    </Typography>
                    <List sx={{ width: '100%', p: 0 }}>
                        <ListItem alignItems="flex-start" sx={{ pl: 0, pr: 0 }}>
                            <ListItemText>
                                Calcity Tablet is a nutritional supplement used in the treatment of calcium deficiency.
                                It is prescribed when your diet alone cannot provide the necessary calcium your body needs.
                                It increases the absorption of calcium and helps to prevent or treat low blood calcium levels.
                            </ListItemText>
                        </ListItem>
                        <Divider component="li" />
                        <ListItem alignItems="flex-start" sx={{ pl: 0, pr: 0 }}>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            variant="h6"
                                            sx={{ color: 'text.primary' }}
                                        >
                                            Key Ingredients:
                                        </Typography>
                                        <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                            Calcium Carbonate 1250 mg, Vitamin D3 IU Tablets
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem alignItems="flex-start" sx={{ pl: 0, pr: 0, display: 'list-item' }}>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            variant="h6"
                                            sx={{ color: 'text.primary' }}
                                        >
                                            Key Benefits:
                                        </Typography>
                                        <div>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Calcity Tablet is a nutritional supplement that is used to prevent or treat low blood calcium levels in people who do not get enough calcium from their diets.
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                It improves the level of calcium in the body.
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                This helps keep your bones strong and healthy and reduce the risk of any bone disorders like osteoporosis.
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Calcium is also necessary for normal functioning of nerves, cells, muscle and heart.
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Taking it as prescribed by the doctor to get the most benefit.
                                            </Typography>
                                        </div>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem alignItems="flex-start" sx={{ pl: 0, pr: 0, display: 'list-item' }}>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            variant="h6"
                                            sx={{ color: 'text.primary' }}
                                        >
                                            Safety Information:
                                        </Typography>
                                        <div>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Read the label carefully before use
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                It is not known whether it is safe to consume alcohol with Calcity Tablet. Please consult your doctor.
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Information regarding the use of Calcity Tablet during pregnancy is not available. Please consult your doctor.
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Information regarding the use of Calcity Tablet during breastfeeding is not available. Please consult your doctor.
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Calcity Tablet does not usually affect your ability to drive.
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                There is limited information available on the use of Calcity Tablet in patients with kidney disease. Please consult your doctor.
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                There is limited information available on the use of Calcity Tablet in patients with liver disease. Please consult your doctor.
                                            </Typography>
                                        </div>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </>
    );
};

export default Calcity;
