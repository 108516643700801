import { Routes, Route } from "react-router-dom";
import usePageTitle from "../utils/hooks/usePageTitle";

import UserLayout from "../layouts/UserLayout";
import Page404 from "../components/common/Page404";
import Dashboard from "../user/pages/Dashboard";
import Products from "../user/pages/Products";
import ProductDetails from "../user/pages/ProductDetails";
import ContactUs from "../user/pages/ContactUs";
import AboutUs from "../user/pages/AboutUs";
// import Testimonials from "../user/pages/Testimonials";
import ClinicalTrials from "../user/pages/ClinicalTrials";

const UserRoutes = () => {
  const titleMap = {
    "/": "User Dashboard",
    "/dashboard": "User Dashboard",
    "/products": "Our Products",
    "/contact-us": "Contact Us",
    "/about-us": "About Us",
    "/clicnical-trials": "clicnical-trials",
    "/products/moxidef-cv": "Moxidef - CV - 625",
    "/products/calcity": "Calcity",
    "/products/zycril": "Zycril",
    "/products/acenofen": "Acenofen",
  };
  usePageTitle(titleMap);

  return (
    <Routes>
      <Route path="/" element={<UserLayout />}>
        <Route path="" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:id" element={<ProductDetails />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/clicnical-trials" element={<ClinicalTrials />} />
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  )
}

export default UserRoutes;
