import * as React from 'react';
import { Divider, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import zycril from "../../../assets/banners/zycril.jpeg";
import { Helmet } from 'react-helmet';

const Zycril = () => {
    const product = {
        name: "Mitsun Pharma - Zycril",
        description:
            "Zycril is an iron supplement designed to treat and prevent iron deficiency anemia. It helps your body maintain healthy red blood cells, improves energy levels, and supports overall health. Ideal for individuals with low iron levels or increased iron requirements.",
        price: "Yet to update",
        image: zycril,
    };

    return (
        <>
            <Helmet>
                <title>{product.name}</title>
                <meta name="description" content={product.description} />
                <meta name="keywords" content="Zycril, zycril, Iron Supplement, iron tablets, anemia treatment, Mitsun Pharma" />
                <meta name="robots" content="index,follow" />
                <meta property="og:title" content={product.name} />
                <meta property="og:description" content={product.description} />
                <meta property="og:image" content={product.image} />
                <meta property="og:url" content="https://mitsunpharma.com/products/zycril" />
                <meta property="og:type" content="product" />
                <meta charset="UTF-8" />
                <meta name="author" content="Mitsun Pharma" />
                <link rel="canonical" href="https://mitsunpharma.com/products/zycril" />
                <meta name="google-site-verification" content="KDeexNvzIpvRxAsVHl4ONgzWXLw2-bzfvpk9_1GTyUk" />
            </Helmet>
            <Grid container rowSpacing={1} sx={{ mt: 2 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid size={{ xs: 12, sm: 12, md: 5 }}>
                    <img
                        src={zycril}
                        alt="zycril"
                        loading="lazy"
                        style={{ width: "-webkit-fill-available", height: 250 }}
                    />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 7 }}>
                    <Typography variant="h5" sx={{ color: 'text.primary' }}>
                        Information about Zycril Tablet with Ferrous Ascorbate & Folic Acid | For Mineral Support
                    </Typography>
                    <List sx={{ width: '100%', p: 0 }}>
                        <ListItem alignItems="flex-start" sx={{ pl: 0, pr: 0 }}>
                            <ListItemText>
                                Zycril Tablet is a health supplement with minerals primarily used to manage nutritional deficiency anaemia related to folic acid and iron deficiency. It can help increase red blood cells and folate levels in the body. It may also help in oxygen transportation and haemoglobin formation for increased energy and metabolism.
                            </ListItemText>
                        </ListItem>
                        <Divider component="li" />
                        <ListItem alignItems="flex-start" sx={{ pl: 0, pr: 0 }}>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            variant="h6"
                                            sx={{ color: 'text.primary' }}
                                        >
                                            Key Ingredients:
                                        </Typography>
                                        <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                            Ferrous Ascorbate IP equivalent to elemental Iron, Folic Acid IP, Excipients
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem alignItems="flex-start" sx={{ pl: 0, pr: 0, display: 'list-item' }}>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            variant="h6"
                                            sx={{ color: 'text.primary' }}
                                        >
                                            Key Benefits:
                                        </Typography>
                                        <div>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Iron replenishes the iron stores in the body and supports the production of haemoglobin
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Folic acid helps the body make red blood cells which ensures a proper supply of oxygen to the body parts
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                The tablet can help in red blood cell development and contains a vital mineral that is required for haemoglobin formation
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                It helps in oxygen transportation from the lungs to the whole body
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                It improves energy and focus and can also help with various bodily functions
                                            </Typography>
                                        </div>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem alignItems="flex-start" sx={{ pl: 0, pr: 0, display: 'list-item' }}>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            variant="h6"
                                            sx={{ color: 'text.primary' }}
                                        >
                                            Safety Information:
                                        </Typography>
                                        <div>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Read the label carefully before use
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Store in a cool, dry place and protect from moisture
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Tablets are hygroscopic in nature. Exposure to humid conditions may soften the tablets affecting their appearance and shape
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Cutting of strip to be avoided while dispensing/usage
                                            </Typography>
                                            <Typography sx={{ display: 'list-item', listStyle: 'disc', ml: 2 }}>
                                                Keep out of reach of children
                                            </Typography>
                                        </div>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </>
    );
};

export default Zycril;
