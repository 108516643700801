import * as React from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from '@mui/material/styles';
import AddIcCall from '@mui/icons-material/AddIcCall';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
    Box,
    AppBar,
    Toolbar,
    Button,
    IconButton,
    Container,
    Divider,
    MenuItem,
    Drawer,
} from '@mui/material';

import {
    MenuIcon,
    CloseRoundedIcon,
} from "../../utils/iconList";

import mLogo from "../../assets/images/mitsun-logo-trans.png";
import SitemarkIcon from './SitemarkIcon';

const StyledToolbar = styled(Toolbar)(({ theme }) => {
    return ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexShrink: 0,
        // borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
        // backdropFilter: 'blur(24px)',
        border: '1px solid',
        borderColor: (theme.vars || theme).palette.divider,
        backgroundColor: 'none',
        // backgroundColor: theme.vars
        //     ? `rgba(${theme.vars.palette.background.defaultChannel} / 0.4)`
        //     : alpha(theme.palette.background.default, 0.4),
        boxShadow: (theme.vars || theme).shadows[1],
        padding: '8px 12px',
        minHeight: '100px',
    })
});

const AppAppBar = () => {
    const [open, setOpen] = React.useState(false);
    const { pathname } = useLocation();
    console.log(pathname);

    const [activeMenu, setActiveMenu] = React.useState(pathname);
    let navigate = useNavigate();
    // const theme = useTheme();

    React.useEffect(() => {
        setActiveMenu(pathname);
    }, [pathname]);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };


    const handleMenuClick = (menu) => {
        setActiveMenu(menu); // Set the clicked menu as active
    };

    // const handleProd = () => {
    //     // let clsList = document.getElementById("hoverMenu").classList;
    //     // if (clsList && clsList.length) {
    //     //     clsList.remove('show');
    //     // }
    //     navigate("/products");
    // }

    const nvigationMobile = (route) => {
        setOpen(false);
        navigate(route);
    }

    // For the product menus
    // const cardData = [
    //     {
    //         img: calcity,
    //         tag: 'Calcity',
    //         title: 'Calcity',
    //         route: 'products/calcity',
    //         description:
    //             'Stay ahead of the curve with the latest design trends and insights. Our design team shares their expertise on creating intuitive and visually stunning user experiences.',
    //         subItems: [{ name: 'Kate Morrison', avatar: '/static/images/avatar/7.jpg' }],
    //     },
    //     {
    //         img: zycril,
    //         tag: 'Zycril',
    //         title: "Zycril",
    //         route: 'products/zycril',
    //         description:
    //             "Take a look at our company's journey and the milestones we've achieved along the way. From humble beginnings to industry leader, discover our story of growth and success.",
    //         subItems: [{ name: 'Cindy Baker', avatar: '/static/images/avatar/3.jpg' }],
    //     },
    //     {
    //         img: moxidef,
    //         tag: 'Moxidef',
    //         title: 'Moxidef',
    //         route: 'products/moxidef',
    //         description:
    //             'Our recent product updates are designed to help you maximize efficiency and achieve more. Get a detailed overview of the new features and improvements that can elevate your workflow.',
    //         subItems: [{ name: 'Travis Howard', avatar: '/static/images/avatar/2.jpg' }],
    //     },
    //     {
    //         img: acenofen,
    //         tag: 'Acenofen',
    //         title: 'Acenofen',
    //         route: 'products/acenofen',
    //         description:
    //             'Our recent product updates are designed to help you maximize efficiency and achieve more. Get a detailed overview of the new features and improvements that can elevate your workflow.',
    //         subItems: [{ name: 'Travis Howard', avatar: '/static/images/avatar/2.jpg' }],
    //     },
    // ];

    // const handleCardClick = (item) => {
    //     navigate(item.route)
    // }

    return (
        <AppBar
            position="sticky"
            enableColorOnDark
            sx={{
                boxShadow: 0,
                bgcolor: 'white',
                backgroundImage: 'none',
                // mt: 'calc(var(--template-frame-height, 0px) + 28px)',
            }}
        >
            <Container maxWidth="lg" sx={{
                "&.MuiContainer-maxWidthLg": {
                    maxWidth: "100%",
                    padding: 0,
                    minHeight: '100px'
                },
            }}>
                <StyledToolbar variant="dense" disableGutters={true}>
                    <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center', px: 0 }}>
                        <SitemarkIcon
                            imageUrl={mLogo}
                            altText="Custom Logo"
                            sx={{ height: 50, width: 150, cursor: 'pointer', }}
                            handleClick={() => navigate("/dashboard")}
                        />
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {/* <div className="menu">
                            <Button className='mui-txt-btn menu-item dropdown' variant="text" size="small" onClick={() => navigate("/products")}>
                                Products <ArrowDropDownIcon />
                                <ul class="submenu">
                                    <li class="submenu-item dropdown">
                                        <a href="#">VITAMINS / MINERALS / NUTRIENTS  <ArrowRightIcon /></a>
                                        <ul class="submenu">
                                            <li><a href="#" onClick={() => navigate("/products/calcity")}>Calcity</a></li>
                                            <li><a href="#" onClick={() => navigate("/products/calcity")}>Zycril</a></li>
                                        </ul>
                                    </li>
                                    <li class="submenu-item dropdown">
                                        <a href="#">ANTI-INFECTIVES <ArrowRightIcon /></a>
                                        <ul class="submenu">
                                            <li><a href="#" onClick={() => navigate("/products/calcity")}>Moxidef - CV - 625</a></li>
                                        </ul>
                                    </li>
                                    <li class="submenu-item dropdown">
                                        <a href="#">PAIN / ANALGESICS <ArrowRightIcon /></a>
                                        <ul class="submenu">
                                            <li><a href="#" onClick={() => navigate("/products/calcity")}>Moxidef - CV - 625</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </Button>
                        </div> */}
                        <Button variant="text"
                            className={['mui-txt-btn', (activeMenu == '/' || activeMenu == '/dashboard') ? 'active-mnu' : '']}
                            size="small" onClick={() => navigate("/dashboard")}>
                            Home
                        </Button>
                        <div className="menu">
                            <Button
                                className={['mui-txt-btn menu-item dropdown', (activeMenu.includes('products')) ? 'active-mnu' : '']}
                                variant="text"
                                size="small"
                                onClick={(e) => {
                                    if (!e.target.closest(".submenu")) {
                                        navigate("/products");
                                    }
                                }}
                            >
                                Products <ArrowDropDownIcon />
                                <ul className="submenu">
                                    <li className="submenu-item dropdown">
                                        <a href="#">
                                            VITAMINS / MINERALS / NUTRIENTS <ArrowRightIcon />
                                        </a>
                                        <ul className="submenu">
                                            <li>
                                                <a
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        navigate("/products/calcity");
                                                    }}
                                                    style={{ color: (activeMenu == '/products/calcity') ? '#ED217C' : 'black' }}
                                                >
                                                    Calcity
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        navigate("/products/zycril");
                                                    }}
                                                    style={{ color: (activeMenu == '/products/zycril') ? '#ED217C' : 'black' }}
                                                >
                                                    Zycril
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="submenu-item dropdown">
                                        <a href="#">
                                            ANTI-INFECTIVES <ArrowRightIcon />
                                        </a>
                                        <ul className="submenu">
                                            <li>
                                                <a
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        navigate("/products/moxidef-cv");
                                                    }}
                                                    style={{ color: (activeMenu == '/products/moxidef-cv') ? '#ED217C' : 'black' }}
                                                >
                                                    Moxidef - CV - 625
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="submenu-item dropdown">
                                        <a href="#">
                                            PAIN / ANALGESICS <ArrowRightIcon />
                                        </a>
                                        <ul className="submenu">
                                            <li>
                                                <a
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        navigate("/products/acenofen");
                                                    }}
                                                    style={{ color: (activeMenu == '/products/acenofen') ? '#ED217C' : 'black' }}
                                                >
                                                    Pain Relief
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </Button>
                        </div>

                        <Button variant="text"
                            className={['mui-txt-btn', (activeMenu == '/clicnical-trials') ? 'active-mnu' : '']}
                            size="small" onClick={() => navigate("/clicnical-trials")}>
                            R & D Clinical Trials
                        </Button>
                        <Button variant="text" size="small" onClick={() => navigate("/about-us")}
                            className={['mui-txt-btn', (activeMenu == '/about-us') ? 'active-mnu' : '']}>
                            About Us
                        </Button>
                        <Button variant="text" size="small" onClick={() => navigate("/contact-us")}
                            className={['mui-txt-btn', (activeMenu == '/contact-us') ? 'active-mnu' : '']}>
                            Contact Us
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            gap: 1,
                            alignItems: 'center',
                        }}
                    >
                        <Button className='phone-btn' variant="outlined" startIcon={<AddIcCall />} href={"tel:+91 6369082584"}>
                            Call Us: +91 6369082584
                        </Button>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, gap: 1 }}>
                        {/* <ColorModeIconDropdown size="medium" /> */}
                        <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer
                            anchor="top"
                            open={open}
                            onClose={toggleDrawer(false)}
                            PaperProps={{
                                sx: {
                                    top: 'var(--template-frame-height, 0px)',
                                },
                            }}
                        >
                            <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <IconButton onClick={toggleDrawer(false)}>
                                        <CloseRoundedIcon />
                                    </IconButton>
                                </Box>
                                <MenuItem onClick={() => nvigationMobile('/products')}>Products</MenuItem>
                                <MenuItem onClick={() => nvigationMobile('/clicnical-trials')}>R & D Clinical Trials</MenuItem>
                                <MenuItem onClick={() => nvigationMobile('/about-us')}>About Us</MenuItem>
                                <MenuItem onClick={() => nvigationMobile('/contact-us')}>Contact Us</MenuItem>
                                <Divider sx={{ my: 3 }} />
                                {/* <MenuItem>
                                    <Button color="primary" variant="contained" fullWidth>
                                        Sign up
                                    </Button>
                                </MenuItem>
                                <MenuItem>
                                    <Button color="primary" variant="outlined" fullWidth>
                                        Sign in
                                    </Button>
                                </MenuItem> */}
                            </Box>
                        </Drawer>
                    </Box>
                </StyledToolbar>
            </Container>
        </AppBar>
    );
}

export default AppAppBar;
