import * as React from 'react';
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

const OurValues = () => {

    const contData = [
        {
            title: "Phased Growth Strategy",
            description: `We prioritize sustainability by focusing on one area at a time, building strong foundations before expanding further`,
            no: '01',
        },
        {
            title: "Commitment to Quality",
            description: `We partner with trusted manufacturers to ensure that only the best medications are delivered to our clients`,
            no: '02',
        },
        {
            title: "Ethical Practices",
            description: `Integrity is at the heart of everything we do, guiding our decisions and relationships`,
            no: '03',
        },
        {
            title: "Phased Growth Strategy",
            description: `From employees to clients, we believe in building lasting relationships based on trust and respect`,
            no: '04',
        },
        {
            title: "Community Focus",
            description: `We are committed to giving back through social initiatives that align with our mission`,
            no: '05',
        },
    ]

    return (
        <>
            {contData.map((item, index) => <Grid size={{ xs: 1, sm: 1, md: 4 }}>
                <Card className='text-left value-card' sx={{ background: 'none', border: 'none' }} key={index}>
                    <CardHeader
                        sx={{}}
                        title={
                            <Typography gutterBottom className='font-pink py-0 my-0' variant="h6" sx={{ pt: 1, fontWeight: "bold" }} >
                                {item.no}.
                            </Typography>
                        }
                        subheader={
                            <Typography gutterBottom className='py-0 my-0' variant="h5" sx={{ pt: 1, fontWeight: "700" }} >
                                {item.title}
                            </Typography>}
                    />
                    <Divider />
                    <CardContent sx={{ mt: 1 }}>
                        <Typography variant="span" sx={{ fontWeight: 'normal' }}>
                            {item.description}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>)}
        </>
    )
}

export default OurValues;
