import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardHeader, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from "react-router-dom";
import { httpMethods } from '../../utils/axios';
import { makeToast } from '../../utils/toast';

const Products = () => {
    const navigate = useNavigate();
    const contData = [
        {
            title: "VITAMINS / MINERALS / NUTRIENTS",
            subItems: [
                { name: `Calcity`, slug: `calcity`, molecules: `Calcium Carbonate 1250 mg, Vitamin D3 IU Tablets` },
                { name: `Zycril`, slug: `zycril`, molecules: `Ferrous Ascorbate 100 mg, Folic Acid 1.5 mg and Zing Sulphate 22.5 mg Tablets` }
            ],
        },
        {
            title: "ANTI-INFECTIVES",
            subItems: [
                { name: `Moxidef - CV - 625`, slug: `moxidef-cv`, molecules: `Amoxycillin 500 mg + Clavulanic Acid 125 mg Tablets` },
            ],
        },
        {
            title: "PAIN / ANALGESICS",
            subItems: [
                { name: `Acenofen`, slug: `acenofen`, molecules: `Aceclofenac 100 mg, Paracetamol 325 mg Tablets` },
            ],
        },
    ]

    // React.useEffect(() => {
    //     getAllProduct();
    // }, []);

    const getAllProduct = () => {
        httpMethods.getMethod('/tutorials').then(data => {
            console.log(data);
        }).catch(err => {
            makeToast("error", err.name, err.message)
        }).finally(fn => {
            // console.log("For loader");
        });
    }

    return (
        <div style={{ padding: '30px' }}>
            <Typography variant="h3" sx={{ color: 'text.primary', mt: 0 }}>
                Our Products
            </Typography>
            <Grid
                container
                sx={{ mt: 2 }}
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 1, sm: 8, md: 12 }}
                alignItems="stretch"
            >
                {contData.map((item, index) => (
                    <Grid key={index} size={{ xs: 2, sm: 4, md: 4 }}>
                        <Card
                            sx={{
                                background: 'none',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%'
                            }}
                            key={index}
                        >
                            <CardHeader
                                title={
                                    <Typography gutterBottom variant="h6" sx={{ fontWeight: "normal" }} >
                                        {item.title}
                                    </Typography>
                                }
                            />
                            <Divider />
                            <CardContent sx={{ flexGrow: 1 }}>
                                <List sx={{ width: '100%', p: 0 }}>
                                    {item.subItems && item.subItems.map((sItem, sIndex) =>
                                        <React.Fragment key={sIndex}>
                                            <ListItem
                                                sx={{
                                                    pt: 0, pb: 0, cursor: 'pointer',
                                                    ":hover": {
                                                        backgroundColor: '#e6e6e6',
                                                        color: '#ED217C'
                                                    }
                                                }}
                                                onClick={() => {
                                                    navigate(sItem.slug);
                                                }}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        {sItem.name.charAt(0)}
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={sItem.name} secondary={sItem.molecules} />
                                            </ListItem>
                                            {item.subItems.length - 1 !== sIndex && <Divider variant="inset" component="li" />}
                                        </React.Fragment>
                                    )}
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}

export default Products;
