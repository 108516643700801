import * as React from 'react';
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { tabletImgs } from '../../utils/siteImgs';

const OurProducts = () => {
    const contData = [
        {
            description: `Calcity: Calcium Carbonate(1250mg) + Vitamin D3 Tablets`,
            image: tabletImgs.tabletWhite,
        },
        {
            description: `Zycril: Ferrous Ascorbate(100mg) + Folic Acid(1.5mg) + Zing Sulphate(22.5mg)`,
            image: tabletImgs.tabletWhite,
        },
        {
            description: `Moxidef - CV - 625: Amoxycillin(500mg) + Clavulanic Acid(125mg)`,
            image: tabletImgs.tabletWhite,
        },
        {
            description: `Acenofen: Aceclofenac(100mg) + Paracetamol(325mg)`,
            image: tabletImgs.tabletWhite,
        },
    ]

    return (
        <>
            <Grid size={{ xs: 1, sm: 1, md: 2 }}></Grid>
            {contData.map((item, index) => <Grid size={{ xs: 1, sm: 1, md: 2 }}>
                <Card className='prd-card' sx={{ background: 'none', }} key={index}>
                    <CardHeader
                        sx={{}}
                        title={
                            <img
                                src={item.image}
                                alt={item.image}
                                style={{
                                    maxWidth: "100%",
                                }}
                            />
                        }
                    />
                    <Divider />
                    <CardContent sx={{ mt: 1 }} className='bg-pink prod-box p-2 d-flex align-items-center'>
                        <Typography variant="span" sx={{}} className='text-white'>
                            {item.description}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>)}
            <Grid size={{ xs: 1, sm: 1, md: 2 }}></Grid>
        </>
    )
}

export default OurProducts;
