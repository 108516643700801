import { toast } from "react-toastify";
import { Bounce, Slide, Flip, Zoom } from "react-toastify";

export const makeToast = (type, title, message) => {
    const toastConfig = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
    };

    const toastContent = (
        <div>
            <strong>{title || "Mitsun Pharma"}</strong>
            <p>{message || ""}</p>
        </div>
    );

    clearToast();

    if (type) {
        toast[type](toastContent, toastConfig);
    } else {
        toast(toastContent, toastConfig);
    }
};

// Clear all toasts
export const clearToast = () => {
    toast.dismiss();
};
