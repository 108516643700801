import Marquee from "react-fast-marquee";

const ReactMarquee = (props) => {
    console.log(props);
    return (
        <Marquee style={props.style} pauseOnHover={true}>
            {props.children}
        </Marquee>
    )
};

export default ReactMarquee;
