import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SitemarkIcon = ({ imageUrl, altText = "Custom Icon", sx, handleClick }) => {
    return (
        <SvgIcon
            component="div"
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 21,
                width: 100,
                mr: 2,
                ...sx,
            }}
            onClick={handleClick}
        >
            <img
                src={imageUrl}
                alt={altText}
                style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                    objectFit: "contain",
                }}
            />
        </SvgIcon>
    );
}

export default SitemarkIcon;
