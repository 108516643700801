import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import { TextField, Divider } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from "yup";
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

// const containerStyle = {
//     width: '400px',
//     height: '400px',
// };

// const center = {
//     lat: -3.745,
//     lng: -38.523,
// };

const ContactUs = () => {

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            message: '',
        },
        validationSchema: Yup.object().shape({
            firstName: Yup.string().required("Please enter First Name"),
            lastName: Yup.string().required("Please enter Last Name"),
            mobile: Yup.number().positive().integer().required("Please enter Mobile Number"),
            message: Yup.string().required("Please enter password"),
            email: Yup.string()
                .email("Invalid email address")
                .required("Please enter email"),
        }),
        onSubmit: values => {
            console.log(formik);
            alert(JSON.stringify(values, null, 2));
        },
    });

    // const { isLoaded } = useJsApiLoader({
    //     id: 'google-map-script',
    //     googleMapsApiKey: 'YOUR_API_KEY',
    // })

    // const [map, setMap] = React.useState(null)

    // const onLoad = React.useCallback(function callback(map) {
    //     const bounds = new window["google"].maps.LatLngBounds(center)
    //     map.fitBounds(bounds)

    //     setMap(map)
    // }, [])

    // const onUnmount = React.useCallback(function callback(map) {
    //     setMap(null)
    // }, [])

    return (
        <div style={{ padding: '30px' }}>
            <Grid container rowSpacing={1} sx={{}} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid size={12} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Typography variant="h1" sx={{ color: 'text.primary' }}>
                        Contact Us
                    </Typography>
                </Grid>
                <Grid size={12} sx={{}}>
                    <Card sx={{ background: 'none' }}>
                        <CardContent>
                            <Typography component="div" variant="h3">
                                Got a Question?
                            </Typography>
                            <Typography variant="h3" component="div">
                                Please reach us.
                            </Typography>
                            <Grid container sx={{ mt: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid size={{ xs: 12, sm: 12, md: 6 }} sx={{ mt: 1, mb: 1 }}>
                                    <Typography variant="h6" sx={{ fontWeight: 'normal', color: 'text.primary', fontSize: '17px' }}>
                                        First Name:
                                    </Typography>
                                    <TextField
                                        id="firstName"
                                        name="firstName"
                                        type='text'
                                        size="small"
                                        sx={{ width: '100%', }}
                                        onChange={formik.handleChange}
                                        value={formik.values.firstName}
                                        className={formik.touched.firstName && formik.errors.firstName ? 'error-input' : ''}
                                    />
                                    {formik.touched.firstName && formik.errors.firstName ? (
                                        <Typography variant='subtitle1' className="error-txt">{formik.errors.firstName}</Typography>
                                    ) : null}
                                </Grid>
                                <Grid size={{ xs: 12, sm: 12, md: 6 }} sx={{ mt: 1, mb: 1 }}>
                                    <Typography variant="h6" sx={{ fontWeight: 'normal', color: 'text.primary', fontSize: '17px' }}>
                                        Last Name
                                    </Typography>
                                    <TextField
                                        id="lastName"
                                        name="lastName"
                                        type='text'
                                        size="small"
                                        sx={{ width: '100%', }}
                                        className={formik.touched.lastName && formik.errors.lastName ? 'error-input' : ''}
                                        onChange={formik.handleChange}
                                        value={formik.values.lastName}
                                    />
                                    {formik.touched.lastName && formik.errors.lastName ? (
                                        <Typography variant='subtitle1' className="error-txt">{formik.errors.lastName}</Typography>
                                    ) : null}
                                </Grid>
                                <Grid size={{ xs: 12, sm: 12, md: 6 }} sx={{ mt: 1, mb: 1 }}>
                                    <Typography variant="h6" sx={{ fontWeight: 'normal', color: 'text.primary', fontSize: '17px' }}>
                                        E-Mail
                                    </Typography>
                                    <TextField
                                        id="email"
                                        name="email"
                                        size="small"
                                        type='email'
                                        sx={{ width: '100%', }}
                                        className={formik.touched.email && formik.errors.email ? 'error-input' : ''}
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <Typography variant='subtitle1' className="error-txt">{formik.errors.email}</Typography>
                                    ) : null}
                                </Grid>
                                <Grid size={{ xs: 12, sm: 12, md: 6 }} sx={{ mt: 1, mb: 1 }}>
                                    <Typography variant="h5" sx={{ fontWeight: 'normal', color: 'text.primary', fontSize: '17px' }}>
                                        Mobile No.
                                    </Typography>
                                    <TextField
                                        id="mobile"
                                        name="mobile"
                                        size="small"
                                        type="number"
                                        sx={{ width: '100%', }}
                                        className={formik.touched.mobile && formik.errors.mobile ? 'error-input' : ''}
                                        onChange={formik.handleChange}
                                        value={formik.values.mo}
                                    />
                                    {formik.touched.mobile && formik.errors.mobile ? (
                                        <Typography variant='subtitle1' className="error-txt">{formik.errors.mobile}</Typography>
                                    ) : null}
                                </Grid>
                                <Grid size={12} sx={{ mt: 1, mb: 1 }}>
                                    <Typography variant="h5" sx={{ fontWeight: 'normal', color: 'text.primary', fontSize: '17px' }}>
                                        Message
                                    </Typography>
                                    <TextField
                                        id="message"
                                        name="message"
                                        size="small"
                                        type='text'
                                        multiline
                                        rows={6}
                                        sx={{ width: '100%' }}
                                        className={formik.touched.message && formik.errors.message ? 'error-input' : ''}
                                        onChange={formik.handleChange}
                                        value={formik.values.message}
                                    />
                                    {formik.touched.message && formik.errors.message ? (
                                        <Typography variant='subtitle1' className="error-txt">{formik.errors.message}</Typography>
                                    ) : null}
                                </Grid>
                                <Grid size={12} sx={{ mt: 1, mb: 1 }}>
                                    <Button variant="contained" type='submit' color="info" onClick={() => { formik.handleSubmit() }}>
                                        Submit
                                    </Button>
                                    <Button
                                        color="primary" variant="outlined" sx={{ ml: 1, }} onClick={() => { formik.handleReset() }}>Reset</Button>
                                </Grid>
                            </Grid>
                            <Divider sx={{ my: 3 }} />
                            <Grid container sx={{ mt: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid size={{ xs: 12, sm: 12, md: 6 }} sx={{}}>
                                    <Card>
                                        <CardContent sx={{}}>
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                                sx={{ fontWeight: 600, mt: 2 }}
                                            >
                                                Corporate Address:
                                            </Typography>
                                            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
                                                Will update you soon!
                                            </Typography>
                                            <Divider sx={{ my: 2 }} />
                                            {/* Address 2 */}
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                                sx={{ fontWeight: 600, mt: 2 }}
                                            >
                                                Registered Address:
                                            </Typography>
                                            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
                                                Will update you soon!
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 12, md: 6 }} sx={{}}>
                                    <Card>
                                        <CardContent sx={{}}>
                                            Map location
                                            {/* <GoogleMap
                                                mapContainerStyle={containerStyle}
                                                center={center}
                                                zoom={10}
                                                onLoad={onLoad}
                                                onUnmount={onUnmount}
                                            >
                                                Child components, such as markers, info windows, etc.
                                                <></>
                                            </GoogleMap> */}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default ContactUs;
